export default {
  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '.container': {
      '.navItemDropdownMenu': {
        background: 'transparent',
        width: 'fit-content'
      },
      background: 'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)'
    },
    '.containerScrolled': {
      backgroundColor: 'primary',
      '.navItemDropdownMenu': {
        width: 'fit-content'
      },
      '.smallNavMenu': {
        '.navItem': {
          // color: 'dark',
          color: 'light',
          margin: ['', '', '', '0.75rem', '1rem'],
          a: {
            fontSize: '1rem',
            ':hover': {
              color: 'lightgrey',
              textDecoration: 'underline'
            }
          }
        },
        '.reservations': {
          // margin: '0rem',
          a: {
            padding: '1rem',
            border: 'solid 1px',
            borderColor: 'light'
          }
        }
      }
    },

    '.logoLocationContainer': {
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      height: 'fit-content',
      '.logo': {
        maxWidth: '150px',
        marginBottom: '0.5rem',
        img: {}
      },
      '.logoScrolled ': {
        maxWidth: '140px',
        marginBottom: '0.5rem',
        maxheight: 'unset',
        filter: 'brightness(0) invert(1)',
        display: 'inline',
        img: {
          maxHeight: 'unset',
          maxWidth: '140px',
          height: 'unset'
        }
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        // color: 'dark',
        color: 'white',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          fontFamily: 'body',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgba(255,255,255,0.5)',
      div: {
        backgroundColor: 'dark'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'white',
      width: ['85%', '60%', '50%', '40%'],
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '200px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.navItem': {
        m: '0rem',
        cursor: 'normal',
        a: {
          cursor: 'pointer',
          display: 'flex',
          padding: '0.75rem',
          fontFamily: 'body',
          fontSize: ['0.9rem', '', '1rem'],
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.navItemDropdownMenu': {
        background: 'transparent',
        width: 'fit-content',
        position: 'static',
        borderTop: '1px solid'
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['', '', '', 'stretch'],
    borderTop: '2px solid',
    borderColor: 'primary',
    '::after': {
      content: `"© ${new Date().getFullYear()} Brazi's"`,
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '2rem 0rem',
      marginBottom: ['40px', '', '', '0px'],

      order: '6',
      textAlign: 'center',
      fontFamily: 'body'
    },
    '.logo': {
      maxWidth: '200px'
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      variant: 'customVariants.title',
      order: 'unset',
      color: 'black',
      borderBottom: '0rem',
      fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
      marginBottom: ['0rem', '', '', '0.5rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', ''],
      justifyContent: 'flex-start'
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },

    '.aboutColumn': {
      backgroundColor: 'background'
    },

    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'background',
      // margin: ['', '', '', '0 0 0 auto'],
      // marginBottom: '0rem',
      // padding: ['', '', '4rem 3rem', '4rem 4rem'],
      // width: ['100%', '', '', '40%'],

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'primary',
          fontSize: ['1rem', '', '1rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.poweredBy  .gonationLogo': {
      filter: 'unset'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'black'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'text'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '1',
    textTransform: 'capitalize',
    width: 'fit-content',
    fontSize: ['2.5rem', '3rem', '3.5rem', '4rem', '4.5rem']
  },
  subtitle: {
    fontWeight: '300',
    margin: '0rem 0rem 1.5rem',
    order: '2',
    fontSize: ['1.75rem', '', '2.5rem', '3rem'],
    opacity: '0.8'
  },

  text: {
    order: '3',
    fontSize: '1rem',
    '*': {
      lineHeight: '1.75'
    }
  },

  ctaButton: {
    order: '4',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem', '2rem'],
    minHeight: ['', '', '70vh'],
    '.lazyload-wrapper': {
      width: ['', '', '60%'],
      boxShadow: '2px 2px 13px grey'
    },
    '.content': {
      width: ['', '', '40%'],
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: 'double 6px',
      pb: '1rem',
      mb: '2rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      paddingLeft: '2rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    color: 'white',
    position: 'relative',
    height: ['50vh', '', '', '100vh'],
    '.slick-slider': {
      height: 'inherit',
      '.slick-list, .slick-track, .slick-slide, .slick-slide > div, img ': {
        height: 'inherit'
      },
      '.slick-prev, .slick-next': {
        display: 'none !important'
      }
    }
  },
  homepageHeroShout: {
    transform: ['translateX(-50%)'],
    position: 'absolute',
    left: '50%',
    bottom: '10vh',
    width: '95%',
    '.shoutWidget': {
      backgroundColor: '#330000de',
      maxWidth: 'unset',
      '.content': {
        width: '100%',
        display: 'flex',
        flexDirection: ['column', '', 'row'],
        justifyContent: 'flex-start',
        alignItems: ['flex-start', '', 'center'],
        p: '0rem',
        '.title': {
          variant: 'customVariants.title',
          order: 1,
          textTransform: 'unset',
          p: '1rem',
          fontSize: ['1rem', '1.1rem', '1.25rem', '1.5rem', '1.5rem'],
          minWidth: '140px',
          color: 'white',
          fontFamily: 'subheading'
        },

        '.date': {
          order: 2,
          fontSize: ['0.8rem', '0.8rem', '0.8rem', '0.8rem', '0.8rem'],
          maxWidth: ['unset', '', '', '130px'],
          flexGrow: 1,
          width: ['100%', '', 'fit-content'],
          p: '1rem',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          minWidth: '100px',
          display: 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: ['flex-start', '', 'center']
        },
        '.text': {
          p: '1rem',
          variant: 'customVariants.text',
          order: 3,
          color: 'white',
          opacity: '1',
          fontSize: ['1.5rem', '1.5rem', '1.75rem', '2.25rem', '2.5rem'],
          textTransform: 'capitalize',
          my: '0rem',
          fontFamily: 'subheading'
        },
        '.imageContainer': {},
        '.shoutCTABtns ': {}
      }
    }
  },
  homepageShout: {
    '.date': {
      display: 'none'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    mt: '1rem'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepagePrivateDining: {
    variant: 'customVariants.homepageContact'
  },

  homepageReservation: {
    mt: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['6rem 1rem', '', '10rem 1rem'],
    flexDirection: 'column',
    backgroundColor: 'white',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },
  homepageBoxes: {
    padding: ['0rem', '', '1rem', '1rem'],

    '.box': {
      borderRadius: '0px',
      width: ['100%', '', 'calc(50% - 2rem)'],
      margin: '1rem',
      height: '75vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: ['1rem', '', '3rem'],
      color: 'white',
      overflow: 'hidden',
      alignItems: 'center',
      textAlign: 'center',
      '::before': {
        content: "''",
        width: '90%',
        position: 'absolute',
        height: '70%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '-1',
        border: 'solid 2px',
        borderColor: 'primary'
      },
      '.title': {
        variant: 'customVariants.title',
        zIndex: '2'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        zIndex: '2'
      },
      '.text': {
        variant: 'customVariants.text',
        color: 'white',
        zIndex: '2',

        p: {
          color: 'white'
        }
      },
      a: {
        order: '4'
      },
      '.image': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        zIndex: '0',
        filter: 'brightness(0.8)'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        order: '4',
        margin: '0rem auto',
        borderColor: 'white',
        color: 'white',
        zIndex: '2',
        ':hover': {
          backgroundColor: 'transparent',
          color: 'white'
        }
      }
    }
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '8rem 1rem'],
    maxWidth: 'unset',
    backgroundColor: 'white',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'white',
    mt: '2rem',
    minHeight: ['100vh', '100vh', '100vh', '100vh', '100vh'],
    '.section': {
      backgroundColor: 'rgba(0,0,0,0.3)',
      p: '2rem 1rem',
      height: 'fit-content',
      margin: 'auto'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      mb: '2rem',
      textShadow: '2px 2px 8px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      textShadow: '2px 2px 8px black'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textShadow: '2px 2px 8px black',
      p: {
        color: 'white'
      }
    },

    '.ctaButton': {
      variant: 'buttons.primary',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white'
      }
    },
    a: {
      mt: '2rem',
      '.ctaButton': {
        backgroundColor: 'black',
        color: 'white'
      }
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary'
    }
  },

  aboutOwner: {
    variant: 'customVariants.sideBySide1',
    '.image': {
      objectFit: 'contain',
      objectPosition: 'top'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================

  menuPDFMenu: {
    paddingBottom: '0rem',
    '.content': {
      display: 'none'
    }
  },

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: '#eae0d7',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/low-contrast-linen.png)',
    backgroundBlendMode: 'difference',
    backgroundSize: '355px',
    backgroundRepeat: 'repeat',
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', 'calc(50% - 1rem)', 'calc(50% - 1rem)', 'calc(50% - 1rem)'],
      borderRadius: '0px'
    },
    '.menuCell': {
      borderRadius: '0px'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      marginBottom: '2rem',
      pt: '0rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      fontFamily: 'heading',
      fontSize: ['1.5rem', '', '2rem', '2.25rem'],
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '0rem 0rem 1rem',
      padding: '1rem',
      color: 'secondary',
      fontSize: ['2rem', '', '3rem', '3.5rem'],
      fonWeight: '100'
    },
    '.menuSectionDescription': {
      fontSize: '1.1rem',
      maxWidth: '800px',
      textAlign: 'left',
      p: '0rem 1.5rem',
      mb: '2rem',
      fontWeight: '600'
    },
    '.menuItemName': {
      fontFamily: 'subheading',
      color: 'secondary',
      mb: '0rem'
    },
    '.menuItemDescription': {
      fontWeight: '600'
    }
    // '.menuPriceContainer': {
    //   display: 'none'
    // },
    // '.variantsContainer': {
    //   display: 'none'
    // }
  },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto'
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    // '.albumsContainer': {
    //   display: 'none',
    // },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Events page  ====
  //  ? ========================
  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    color: 'white',
    '.title': {
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem',
      marginBottom: '2rem',
      color: 'white'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'white',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        color: 'white',
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid white',
        margin: '1.5rem 0rem',
        backgroundColor: 'rgba(0,0,0,0.3)',
        '::placeholder': {
          color: 'white'
        }
      },

      textarea: {
        border: 'none',
        color: 'white',
        borderRadius: '0px',
        borderBottom: '1px solid white',
        margin: '1.5rem 0rem',
        backgroundColor: 'rgba(0,0,0,0.3)',
        '::placeholder': {
          color: 'white'
        }
      },
      '.submitBtn': {
        borderRadius: '0px',
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  GiftCardPage: {
    '.title': {
      mb: '2rem'
    },
    '.text': {
      order: 3
    }
  },

  // ? ===========================
  // ? === The Restaurant Page ===
  // ? ===========================

  restaurantPageBoxes: {
    '.contentBoxes': {
      maxWidth: 'unset',
      '.box': {
        flexGrow: 1,
        '.title': {
          variant: 'customVariants.title',
          mt: '1rem',
          fontSize: ['2.5rem', '2.5rem', '2.5rem', '2.5rem', '2.5rem']
        },
        '.subtitle': {
          variant: 'customVariants.subtitle',
          mt: '1rem'
        },
        '.text': {
          variant: 'customVariants.text',
          mt: '1rem'
        }
      }
    }
  }
}
