export default {
  primary: {
    backgroundColor: 'white',
    color: 'dark',
    border: 'solid 2px',
    borderColor: 'primary',
    fontWeight: '500',
    padding: '1.25rem',
    fontSize: '1rem',
    textTransform: 'uppercase',
    borderRadius: '0px',

    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  },
  secondary: {
    backgroundColor: 'black',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    fontWeight: '500',
    padding: '1rem',
    fontSize: '1rem',
    textTransform: 'uppercase',
    borderRadius: '0px',

    ':hover': {
      opacity: '0.6'
    }
  }
}
