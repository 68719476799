export default {
  pageHeroV1: {
    container: {
      height: '70vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.25rem', '', '3.5rem', '4.5rem'],
        marginBottom: 1,
        color: 'white'
      }
    }
  }
}
