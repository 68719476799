export default {
  heading: 'Bilbo Swash Caps, cursive',
  subheading: 'Italiana',
  body: 'Raleway, san-serif',
  monospace: 'Menlo, monospace',
  display: 'Alex Brush, cursive',
  googleFonts: [
    'Nanum Myeongjo:100,200,300,400,500,600,700,800,900',
    'Raleway:100,200,300,400,500,600,700,800,900',
    'Alex Brush:400,500,600,700,800,900',
    'Italiana',
    'Bilbo Swash Caps'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
